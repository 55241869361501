@font-face {
  font-family: 'Ember Condensed Heavy';
  src: local('Ember Condensed Heavy'),
    url('./AmazonEmberDisplayCondensedJ-Heavy.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Ember';
  src: local('Ember'), url('./AmazonEmber_Rg.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Ember';
  src: local('Ember'), url('./AmazonEmber_Bd.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Ember Bold';
  src: local('Ember Bold'), url('./AmazonEmber_Bd.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Sharp Grotesk';
  src: local('Sharp Grotesk'),
    url('./SharpGroteskPanEuroMedium-16.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Sharp Grotesk Bold';
  src: local('Sharp Grotesk Bold'),
    url('./SharpGroteskPanEuroBold-20.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: block;
}
