@import '~@amzn/DMWebUIComponents/dist/global/variables';

.rdrDateDisplayItem,
.rdrDateDisplayItemActive,
.rdrDateDisplayWrapper,
.rdrMonthAndYearWrapper,
.rdrCalendarWrapper,
.rdrDateRangeWrapper {
  background: transparent;
}

.rdrMonth {
  width: 100%;
}

.rdrDayPassive,
.rdrDayDisabled {
  opacity: map-get($glass, glass-4);
  background-color: transparent;
}

.rdrDateDisplayItemActive {
  background-color: map-get($glass-colors, primary-2);
  border-radius: 18px;
  outline: none;
}

.rdrDateDisplayItem input {
  background-color: map-get($glass-colors, primary-2);
  border-radius: 18px;
  width: calc(100% - 6px);
}

.rdrDay .rdrDayNumber span,
.rdrDateDisplayItem,
.rdrDateDisplayItemActive,
.rdrDateDisplayItemActive input,
.rdrDateDisplayItem input,
.rdrMonthPicker select,
.rdrYearPicker select {
  color: map-get($colors, primary);
}
.rdrDayToday .rdrDayNumber span::after {
  background: map-get($colors, primary);
}

.rdrNextPrevButton:active,
.rdrNextPrevButton:focus,
.rdrNextPrevButton:hover {
  background: map-get($glass-colors, primary-3);
  border: 0;
  outline: none;
}

.rdrNextPrevButton {
  background: map-get($glass-colors, primary-2);
  border-radius: 50%;
}

.rdrNextButton i {
  border-color: transparent transparent transparent map-get($colors, primary);
}

.rdrPprevButton i {
  border-color: transparent map-get($colors, primary) transparent transparent;
}
