@import '~@amzn/DMWebUIComponents/dist/global/variables';

$bold: 'Ember Bold';
$condensed: 'Ember Condensed Heavy';
$regular: 'Ember';
$headerHeight: 60px;
$thumbnailSize: 56px;
$largeImageWidth: 200px;
$largeImageHeight: 200px;
$mediumImageWidth: 150px;
$mediumImageHeight: 150px;
$smallImageWidth: 84px;
$smallImageHeight: 84px;
$stickerMinHeight: 80px;
$stickerMinWidth: 130px;
$green: #0c0;

:export {
  headerHeight: 60;
  screenBreakPointsXl: map-get($screen-breakpoints, xl);
}
