@import '~@amzn/DMWebUIComponents/dist/global/mixins';
@import '~@amzn/DMWebUIComponents/dist/global/typeclass';
@import '~@amzn/DMWebUIComponents/dist/global/variables';
@import 'assets/sass/variables';
@import 'assets/fonts';

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: map-get($colors, background);
  color: map-get($colors, primary);
  min-height: 100vh;
  width: 100vw;
  font-family: $regular, Roboto, 'Helvetica Neue', Arial;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  padding: 0;
  border: none;
  background-color: initial;
  color: inherit;
  font: inherit;
  cursor: pointer;
}

input {
  border: none;
  background-color: unset;
}

p {
  margin: 0;
}

ul,
ol {
  padding: 0;
}

h1 {
  @extend .headline-1;
}

h2 {
  @extend .headline-2;
}

h3 {
  @extend .headline-3;
}

h4 {
  @extend .headline-4;
}

music-notification-ribbon music-link {
  vertical-align: middle;
}

music-link {
  display: inline-block;
  vertical-align: top;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  & > span,
  & > a {
    font-size: inherit;
    white-space: normal;
    &:hover {
      color: map-get($colors, accent);
    }
    &:first-child {
      white-space: normal;
    }
  }
}

.align-bottom {
  vertical-align: bottom;
}

.extra-large-text {
  font-size: 16px;
  line-height: 20px;
}

.large-text {
  font-size: 14px;
  line-height: 20px;
}

.medium-text {
  font-size: 13px;
  line-height: 16px;
}

.small-text {
  font-size: 12px;
  line-height: 16px;
}

.MusicLink__inline {
  display: inline;
  vertical-align: unset;
}

.MusicButton__wide {
  width: 250px;
}

.cursor-pointer {
  cursor: pointer;
}

.blur {
  filter: blur($backdrop-blur);
  overflow: hidden;
  height: 100vh;
}

.label {
  @extend .small-text, .uppercase;
  color: map-get($colors, accent);
  font-family: $bold, Roboto, 'Helvetica Neue', Arial;
}

.index {
  text-transform: uppercase;
  color: map-get($glass-colors, primary-4);
  font-family: $bold, Roboto, 'Helvetica Neue', Arial;
}

.header {
  color: map-get($glass-colors, primary-3);
  font-family: $bold, Roboto, 'Helvetica Neue', Arial;
}

.primary-text {
  color: map-get($colors, primary);
  font-family: $regular, Roboto, 'Helvetica Neue', Arial;
}

.secondary-text {
  color: map-get($glass-colors, primary-4);
  font-family: $regular, Roboto, 'Helvetica Neue', Arial;
}

.capitalize {
  text-transform: capitalize;
}

.lower-case {
  text-transform: lowercase;
}

.uppercase {
  text-transform: uppercase;
}

.bodyPrimary {
  @extend .extra-large-text;
  font-family: $regular;
}

.bodySecondary {
  @extend .large-text;
  font-family: $regular;
}

.iconFill {
  music-icon {
    g > path {
      fill: map-get($colors, accent);
    }
  }
}

.iconFillHover {
  music-icon:hover {
    g > path {
      fill: map-get($colors, accent);
    }
  }
}

.dropShadow-light {
  box-shadow: 0 10px 20px map-get($glass-colors, secondary-3);
}

.dropShadow-heavy {
  box-shadow: 0 10px 20px map-get($glass-colors, secondary-4);
}

.backdropBlur-primary {
  backdrop-filter: blur($backdrop-blur);
  background-color: map-get($glass-colors, primary-4);
  @supports not (backdrop-filter: blur($backdrop-blur)) {
    background-color: map-get($colors, primary);
  }
}

.backdropBlur-secondary {
  backdrop-filter: blur($backdrop-blur);
  background-color: map-get($glass-colors, secondary-4);
  @supports not (backdrop-filter: blur($backdrop-blur)) {
    background-color: map-get($colors, secondary);
  }
}

.loading {
  animation: linear infinite alternate 1s loading-animation;
}

@keyframes loading-animation {
  100% {
    background-color: map-get($glass-colors, primary-2);
  }
}

@media only screen and (max-width: map-get($screen-breakpoints, xl)) {
  .extra-large-text {
    font-size: 15px;
    line-height: 20px;
  }

  .large-text {
    font-size: 13px;
    line-height: 16px;
  }

  .medium-text {
    font-size: 12px;
    line-height: 16px;
  }

  .small-text {
    font-size: 11px;
    line-height: 12px;
  }
}
